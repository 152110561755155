import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Color",
  "layout": "pattern-library"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ToggleToolbar = makeShortcode("ToggleToolbar");
const PaletteDisplay = makeShortcode("PaletteDisplay");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ToggleToolbar buttons={[{
      'Guidelines': '/patterns'
    }, {
      'Color': '/patterns/visual-styles/color'
    }, {
      'Typography': '/patterns/visual-styles/typography'
    }, {
      'Iconography': '/patterns/visual-styles/iconography'
    }]} mdxType="ToggleToolbar" />
    <PaletteDisplay mdxType="PaletteDisplay">
  <div noteKeys={['common']}>
    common colors documentation
  </div>
  <div noteKeys={['common.black']}>
    common black colors documentation
  </div>
    </PaletteDisplay>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      